// src/components/primitives/Headers.styled.tsx

import styled from "styled-components";

export const ModuleHeader = styled.h2`
  // display: inline-block;
  color: ${({ theme }) => theme.colors.buttonPrimary};
  // border-radius: ${({ theme }) => theme.borderRadius.medium};
  // transition:
  // transform 0.2s ease,
  // filter 0.2s ease;
  // width: 96%;
  text-align: center;
  padding: 4px 8px;
  font-family: ${({ theme }) => theme.fonts.main};
  user-select: none;
`;

/* export const SubmoduleHeader = styled.h3`
  color: ${({ theme }) => theme.colors.buttonPrimary};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  transition:
    transform 0.2s ease,
    filter 0.2s ease;
  width: 96%;
  text-align: center;
  padding: 8px 16px;
  font-family: ${({ theme }) => theme.fonts.main};
`;
 */
