// src/service/auth/authWebhookSignup.tsx

import React, { useEffect } from "react";
import styled from "styled-components";
import { WEBHOOKS } from "../../config";

// Styled Components for the modal background and window
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalWindow = styled.div`
  width: 300px;
  height: 150px;
  background-color: #1453b8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  color: white;
`;

// Account Setup Modal Component
export const AccountSetupModal: React.FC<{ visible: boolean }> = ({
  visible,
}) => {
  if (!visible) return null;
  return (
    <ModalBackground>
      <ModalWindow>Setting up user account...</ModalWindow>
    </ModalBackground>
  );
};

// -------------------------
// WEBHOOK: SIGN-UP TRIGGER
// -------------------------

interface UserInfo {
  email: string;
  // Add other fields as necessary
}

export const triggerSignUpWorkflow = async (
  userInfo: UserInfo
): Promise<void> => {
  const webhookUrl = WEBHOOKS.SIGN_UP;

  try {
    const response = await fetch(webhookUrl as string, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    });

    if (!response.ok) {
      throw new Error("Failed to trigger the signup workflow.");
    }

    console.log("Signup workflow triggered successfully.");

    // Reload the page after a short delay
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  } catch (error) {
    console.error("Error in sign-up workflow:", error);
  }
};
