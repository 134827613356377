// src/hooks/useUserFolder.ts

import { useEffect, useState, useContext } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { app } from "@config/firebaseConfig";
import { AppContext } from "@contexts/AppContext";
import { FIRESTORE_PATHS } from "config";

const db = getFirestore(app);

export const useUserFolder = () => {
  const { userStore } = useContext(AppContext);
  const userEmail = userStore.email || userStore.testEmail;
  const [userFolder, setUserFolder] = useState<string | null>(null);

  useEffect(() => {
    if (!userEmail) return;

    const fetchFolder = async () => {
      const folderDataRef = doc(
        db,
        FIRESTORE_PATHS.userFilesFolderData(userEmail)
      );
      const folderDataSnap = await getDoc(folderDataRef);
      if (folderDataSnap.exists()) {
        const data = folderDataSnap.data();
        setUserFolder(data.userFolder || null);
      } else {
        setUserFolder(null);
      }
    };

    fetchFolder();
  }, [userEmail]);

  return userFolder;
};
