// src/pages/Make/components/MakeInputJobDescription/subcomponents/SampleJobDescriptionsDropdown.tsx

import React, { useState } from "react";
import styled from "styled-components";

interface SampleJobDescriptionsDropdownProps {
  files: string[];
  onSelect: (file: string) => void;
}

const StyledSelect = styled.select`
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  font-family: ${({ theme }) => theme.fonts.main};
`;

// Helper function to format the file names
const formatFileName = (file: string): string => {
  // Remove .md extension
  const withoutExt = file.replace(/\.md$/, "");
  // Replace underscores with spaces and capitalize each word
  return withoutExt
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const SampleJobDescriptionsDropdown: React.FC<
  SampleJobDescriptionsDropdownProps
> = ({ files, onSelect }) => {
  const [selectedFile, setSelectedFile] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const file = e.target.value;
    setSelectedFile(file);
    onSelect(file);
  };

  return (
    <StyledSelect value={selectedFile} onChange={handleChange}>
      <option value="">Select a sample job description</option>
      {files.map((file) => (
        <option key={file} value={file}>
          {formatFileName(file)}
        </option>
      ))}
    </StyledSelect>
  );
};
