// src/pages/SignIn/SignIn.styled.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "@components/primitives";

export const SignInPageContainer = styled.div`
  width: 100%;
  // min-height: calc(80vh - 56px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SignInContainer = styled(Primitive.ModuleContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 50%;
  height: 50%;
  min-width: 460px;
  max-width: 460px;
  min-height: 460px;
  max-height: 460px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  padding: 0px 12px 0px 12px;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  color: white;
  box-sizing: border-box;
  margin-bottom: 80px;

  @media (max-width: 480px) {
    min-width: calc(100vw - 0px);
    max-width: 100vw;
    width: calc(100vw - 0px);
    min-height: calc(100vh - 0px);
    max-height: calc(100vh - 0px);
    height: calc(100vh - 0px);
    border-radius: 0;
    // padding: 0px 12px 0px 12px;
  }
`;

export const SignInLogoContainer = styled.div`
  width: 72px;
  height: 72px;
  margin-top: -18px;
`;

export const LogoImage = styled.img`
  width: 72px;
  height: 72px;
  filter: invert(1);
`;

export const SignInTitle = styled.div`
  font-size: 1.25rem;
  margin-top: 6px;
  margin-bottom: 28px;
`;

export const SignInSubtitle = styled.div`
  color: #ddd;
  font-size: 0.7rem;
  margin-top: 12px;
  // font-style: italic;
  letter-spacing: 0.33px;
`;
