// src/contexts/AppContext.tsx

import React from "react";
// import tryStore from "../pages/Try/store/TryStore";
import makeStore from "../pages/Make/store/MakeStore";
import userStore from "../stores/UserStore";

export const AppContext = React.createContext({
  // tryStore,
  makeStore,
  userStore,
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <AppContext.Provider value={{ /* tryStore, */ makeStore, userStore }}>
      {children}
    </AppContext.Provider>
  );
};

// export default AppContext;
