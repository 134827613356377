// src/utils/tokenUtils.ts

// Structural comment: This helper decodes a JWT token to extract the email claim.
export function extractEmailFromToken(token: string): string | null {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.email || null;
  } catch (error) {
    console.error("Error extracting email from token:", error);
    return null;
  }
}
