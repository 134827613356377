// src/routes/AppRouter.tsx

import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { AppContext } from "../contexts/AppContext";
import { SignIn } from "../pages/SignIn/SignIn";
import { Dashboard } from "../layouts/Dashboard/Dashboard";
import { BaseResumes } from "../pages/BaseResumes/BaseResumes";
import { MakeModule } from "../pages/Make/Make";
import { UserMakes } from "../pages/UserMakes/UserMakes";
import { SetupResume } from "../pages/SetupResume/SetupResume";
import { TryModule } from "../pages/Try/Try";

export const AppRouter: React.FC = () => {
  const { userStore } = useContext(AppContext);
  const isAuthenticated = userStore.isAuthenticated;

  return (
    <Router>
      <Routes>
        {/* Public route for SignIn, but redirect if already authenticated */}
        <Route
          path="/sign-in"
          element={isAuthenticated ? <Navigate to="/" replace /> : <SignIn />}
        />

        {/* Public route for Try */}
        <Route path="/try" element={<TryModule />} />

        {/* Protected routes (require auth) */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Dashboard
                user={userStore.user}
                testEmail={userStore.testEmail}
              />
            ) : (
              <Navigate to="/sign-in" replace />
            )
          }
        >
          <Route index element={<UserMakes />} />
          <Route path="make" element={<MakeModule />} />
          <Route path="base-resumes" element={<BaseResumes />} />
          <Route path="setup-resume" element={<SetupResume />} />
        </Route>

        {/* Catch-all -> redirect to "/" (or "/sign-in") */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};
