import styled from "styled-components";
import * as Primitive from "@components/primitives";

// Styled Components
export const AccountContainer = styled.div`
  position: relative; /* Removed absolute positioning */
  display: flex;
  align-items: center;
  justify-content: center;

  .top-bar-user {
    opacity: 1;
    transition: opacity 0.2s ease;

    @media (max-width: 640px) {
      display: 0;
    }
  }
`;

export const TopBarUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: grey;
  margin-right: 12px;
  transition: opacity 0.2s ease;

  @media (max-width: 640px) {
    opacity: 0;
  }
`;

export const ButtonContainer = styled.div`
  position: relative; /* Removed absolute positioning */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Fixed width for the circle */
  height: 40px; /* Fixed height for the circle */
  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: 50%; /* Make it circular */
  cursor: pointer; /* Pointer cursor on hover */
  transition: filter 0.3s ease; /* Smooth transition */
  box-shadow: ${({ theme }) => theme.shadows.button}; /* Add shadow */

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 50px; /* Position below the button */
  right: 0;
  width: 200px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 16px;
  z-index: 200; /* Ensure it appears above other elements */
`;

export const DropdownItem = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
  color: #333;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DropdownButton = styled(Primitive.SquareButton)`
  width: 100%;
  padding: 8px 12px;
  margin-top: 8px;
  background-color: #6c757d;
  color: white;
`;

export const LogoutButton = styled(DropdownButton)`
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  color: white;
`;
