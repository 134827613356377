// src/pages/Make/components/MakeInputJobDescription/MakeInputJobDescription.tsx

import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { AppContext } from "@contexts/AppContext";
import { makeWebhook } from "../../utils/makeWebhook";

import {
  MakeSubmoduleContainer,
  MakeSubmoduleHeader,
  MakeButton,
  MakeTextArea,
} from "../../Make.styled";
import {
  CloudUpload as UploadIcon,
  FontDownload as InputIcon,
} from "@mui/icons-material";
import { GoogleDocThumbnail } from "../../../../components/GoogleDocThumbnail/GoogleDocThumbnail";
import { WebhookStatusModal } from "../../../../components/WebhookStatusModal/WebhookStatusModal";
import { WebhookMessage } from "../../utils/webhookConfig";
import { SampleJobDescriptionsDropdown } from "./subcomponents/SampleJobDescriptionsDropdown"; // Import the new dropdown

export const MakeInputAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 100%;
  min-width: 300px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
`;

export const StyledUploadIcon = styled(UploadIcon)`
  color: #aaa;
  font-size: 3rem !important;
`;

export const StyledInputIcon = styled(InputIcon)`
  color: #aaa;
  font-size: 3rem !important;
`;

interface MakeInputJobDescriptionProps {
  onNext: () => void;
  onBack: () => void;
}

// List out all your markdown files in the `SampleJobDescriptions` folder:
const sampleJobDescriptionFiles = [
  "administrative_assistant.md",
  "customer_service_representative.md",
  "data_analyst.md",
  "digital_marketing_specialist.md",
  "electrician.md",
  "financial_analyst.md",
  "graphic_designer.md",
  "human_resources_specialist.md",
  "marketing_manager.md",
  "mechanical_engineer.md",
  "project_manager.md",
  "registered_nurse.md",
  "sales_associate.md",
  "software_developer.md",
  "teacher.md",
];

export const MakeInputJobDescription: React.FC<MakeInputJobDescriptionProps> =
  observer(({ onNext, onBack }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<WebhookMessage | null>(
      null
    );
    const { makeStore } = useContext(AppContext);

    // Persist state whenever inputs change
    useEffect(() => {
      makeStore.persistState();
    }, [makeStore.resumeInput, makeStore.jobDescriptionInput]);

    // Create a mapping of all .md files in the folder, loading them as raw text.
    // @ts-ignore
    const sampleFiles = import.meta.glob(
      "../../../../assets/SampleJobDescriptions/*.md",
      {
        as: "raw",
      }
    );

    // Helper function to strip markdown formatting
    const stripMarkdown = (markdownText: string): string => {
      let text = markdownText;
      // Remove markdown header markers (lines starting with one or more '#' characters)
      text = text.replace(/^#+\s*(.*)$/gm, "$1");
      // Remove bold formatting (e.g., **text**)
      text = text.replace(/\*\*(.*?)\*\*/g, "$1");
      // Remove italic formatting (e.g., *text* or _text_)
      text = text.replace(/(\*|_)(.*?)\1/g, "$2");
      // Remove markdown links: [text](url) -> text
      text = text.replace(/\[(.*?)\]\(.*?\)/g, "$1");
      // Remove inline code formatting
      text = text.replace(/`(.*?)`/g, "$1");
      // Remove strikethrough formatting
      text = text.replace(/~~(.*?)~~/g, "$1");
      return text;
    };

    const handleFileSelect = async (fileName: string) => {
      if (!fileName) return;

      // Clear the current job description text immediately
      makeStore.setJobDescriptionInput("");

      // Construct the file path key as it appears in the glob mapping.
      const filePath = `../../../../assets/SampleJobDescriptions/${fileName}`;
      const loader = sampleFiles[filePath];

      if (!loader) {
        console.error(`No loader found for ${filePath}`);
        return;
      }

      try {
        // loader() returns a promise with the file content as raw text.
        const fileText = await loader();
        // Process the markdown to remove headers and formatting
        const plainText = stripMarkdown(fileText);
        // Update the job description input with the processed text
        makeStore.setJobDescriptionInput(plainText);
      } catch (err) {
        console.error("Error loading file:", err);
      }
    };

    // Handler for text area changes
    const handleJobDescriptionChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      makeStore.setJobDescriptionInput(e.target.value);
    };

    // Function to handle messages from webhook utility
    const handleWebhookMessage = (message: WebhookMessage) => {
      setModalMessage(message);
    };

    const handleNext = async () => {
      console.log("Additional logic for onNext");
      console.log(
        "Owner: ",
        makeStore.selectedDoc?.ownerEmail,
        "Name: ",
        makeStore.selectedDoc?.title,
        "ID: ",
        makeStore.selectedDoc?.id,
        "URL: ",
        makeStore.selectedDoc?.url
      );

      try {
        setIsLoading(true);
        setErrorMessage(null);
        setModalMessage(null);
        setModalOpen(true);

        const docOwner = makeStore.selectedDoc?.ownerEmail;
        const docId = makeStore.selectedDoc?.id;
        const jobDescription = makeStore.jobDescriptionInput;

        if (!docOwner) {
          console.error("Document Owner is missing.");
          setErrorMessage("Document Owner is missing.");
          setModalMessage({
            type: "text",
            content: "Document Owner is missing.",
          });
          setModalOpen(false);
          return;
        }

        if (!docId) {
          console.error("Document ID is missing.");
          setErrorMessage("Document ID is missing.");
          setModalMessage({
            type: "text",
            content: "Document ID is missing.",
          });
          setModalOpen(false);
          return;
        }

        if (!jobDescription) {
          console.error("Job Description is missing.");
          setErrorMessage("Job Description is missing.");
          setModalMessage({
            type: "text",
            content: "Job Description is missing.",
          });
          setModalOpen(false);
          return;
        }

        // Call the webhook with the provided data
        const context = await makeWebhook(
          docOwner,
          docId,
          jobDescription,
          handleWebhookMessage
        );

        if (context.resumePDFurl) {
          makeStore.setResumePDFurl(context.resumePDFurl);
          console.log(
            "MobX Store - resumePDFurl updated:",
            context.resumePDFurl
          );
        }

        // Proceed to the next step
        onNext();
        setModalOpen(false);
      } catch (error) {
        console.error("Error calling makeTryWebhook:", error);
        setErrorMessage("Failed to process your request. Please try again.");
        setModalMessage({
          type: "text",
          content: "Failed to process your request. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <MakeSubmoduleContainer>
        <MakeSubmoduleHeader>Input Job Description</MakeSubmoduleHeader>

        {makeStore.selectedDoc && makeStore.selectedDoc.thumbnailUrl && (
          <div style={{ transform: "scale(0.8)", margin: "0", padding: "0" }}>
            <GoogleDocThumbnail selectedDoc={makeStore.selectedDoc} />
          </div>
        )}

        <MakeInputAreaContainer>
          {errorMessage && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errorMessage}
            </div>
          )}

          {/* Always show the text area */}
          <MakeTextArea
            placeholder="Paste job description here"
            rows={10}
            value={makeStore.jobDescriptionInput}
            onChange={handleJobDescriptionChange}
            disabled={isLoading}
          />

          {/* Always show the sample job descriptions dropdown */}
          <SampleJobDescriptionsDropdown
            files={sampleJobDescriptionFiles}
            onSelect={handleFileSelect}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <MakeButton onClick={onBack} disabled={isLoading}>
              Back
            </MakeButton>
            <MakeButton onClick={handleNext} disabled={isLoading}>
              {isLoading ? "Processing..." : "Make"}
            </MakeButton>
          </div>
        </MakeInputAreaContainer>

        {/* Webhook Status Modal */}
        {modalMessage && (
          <WebhookStatusModal
            isOpen={modalOpen}
            message={modalMessage}
            onClose={() => setModalOpen(false)}
          />
        )}
      </MakeSubmoduleContainer>
    );
  });
