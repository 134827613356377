// src/pages/Try/Try.tsx

import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  MakeModuleContainer,
  ProgressTrackerContainer,
} from "../Make/Make.styled";
import {
  MakeInputResume,
  TryInputJobDescription,
  TryResults,
} from "../Make/components";
import { AppContext } from "@contexts/AppContext";
import { MakeProgressTracker } from "../Make/components/MakeProgressTracker/MakeProgressTracker";

import { getAuth } from "firebase/auth";
import { checkUserFilesDocumentExists } from "@services/auth/auth";
import { triggerSignUpWorkflow } from "@services/auth/authWebhookSignup";

interface TryPageProps {
  /* Props */
}

export const TryModule: React.FC<TryPageProps> = observer(() => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { makeStore } = useContext(AppContext);

  // NEW: Listen for Firebase auth state changes and trigger sign-up if needed.
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Check if the user already has the necessary Firestore entry.
        const exists = await checkUserFilesDocumentExists(user);
        if (!exists) {
          // Trigger the sign-up workflow if not present.
          await triggerSignUpWorkflow({ email: user.email as string });
        }
      }
    });
    return () => unsubscribe();
  }, []);

  // Persist state whenever currentStep changes
  useEffect(() => {
    makeStore.persistState();
  }, [currentStep, makeStore]);

  // Handlers to navigate steps
  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 2));
  const handleBack = () => setCurrentStep((prev) => Math.max(prev - 1, 0));
  const goToStep = (step: number) => setCurrentStep(step);

  return (
    <div>
      <ProgressTrackerContainer id="progress-tracker"></ProgressTrackerContainer>
      <MakeModuleContainer>
        {/* Top Bar */}
        <MakeProgressTracker currentStep={currentStep} goToStep={goToStep} />
        {/* Steps */}
        {currentStep === 0 && <MakeInputResume onNext={handleNext} />}
        {currentStep === 1 && (
          <TryInputJobDescription onNext={handleNext} onBack={handleBack} />
        )}
        {currentStep === 2 && <TryResults onBack={handleBack} />}
      </MakeModuleContainer>
    </div>
  );
});
