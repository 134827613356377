// src/pages/BaseResumes/BaseResumes.tsx

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BaseResumesTable, SetupResumeButtonContainer } from ".";
import {
  BaseResumesContainer,
  BaseResumesHeader,
  BaseResumesStyledTable,
  BaseResumesTableHeader,
  BaseResumesLoadingMessage,
  SetupResumeButton,
  SetupResumeButtonSpan,
  BaseResumesSVGWrapper,
} from ".";
import { SetupResume } from "../SetupResume/SetupResume";
import { useBaseResumes } from "./hooks/useBaseResumes";
import { AddBox as AddIcon } from "@mui/icons-material";
import { BaseResumesTableContainer } from "./BaseResumes.styled";
import {
  LoadingComponent,
  LoadingScreen,
} from "@components/LoadingScreen/LoadingScreen";

export const BaseResumes: React.FC = () => {
  const {
    resumes,
    loading,
    isDeleting,
    isSettingDefault,
    handleDeleteResume,
    handleSetDefaultResume,
  } = useBaseResumes();

  // State to track expanded rows
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  // Function to toggle row expansion
  const toggleRow = (id: string) => {
    // TOGGLE ROW (KEEP THIS)
    /* setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    ); */
  };

  return (
    <BaseResumesContainer>
      <BaseResumesHeader
        className="dashboard-module-header"
        onClick={() => {
          const table = document.getElementById("userBaseResumesTable");
          const button = document.getElementById("btn-setup-resume");
          table?.classList.toggle("hidden");
          button?.classList.toggle("hidden");
        }}
      >
        Base Resumes
      </BaseResumesHeader>

      {loading ? (
        <>
          <LoadingComponent />
        </>
      ) : resumes.length > 0 ? (
        <BaseResumesTableContainer>
          <BaseResumesStyledTable
            id="userBaseResumesTable"
            className="user-makes-table"
          >
            <thead>
              <tr>
                <BaseResumesTableHeader>
                  <span style={{ marginLeft: "-12px" }}>DATE</span>
                </BaseResumesTableHeader>
                <BaseResumesTableHeader>NAME</BaseResumesTableHeader>
                <BaseResumesTableHeader>GDOC</BaseResumesTableHeader>
                <BaseResumesTableHeader>DEFAULT</BaseResumesTableHeader>
                <BaseResumesTableHeader>
                  <span style={{ marginRight: "-16px" }}>{/* DELETE */}</span>
                </BaseResumesTableHeader>
              </tr>
            </thead>
            <tbody>
              <BaseResumesTable
                makes={resumes}
                onDelete={handleDeleteResume}
                onSetDefault={handleSetDefaultResume}
                isDeleting={isDeleting}
                isSettingDefault={isSettingDefault}
                expandedRows={expandedRows} // Pass expandedRows
                toggleRow={toggleRow} // Pass toggleRow function
              />
            </tbody>
          </BaseResumesStyledTable>
        </BaseResumesTableContainer>
      ) : (
        <BaseResumesLoadingMessage>No resumes found.</BaseResumesLoadingMessage>
      )}

      <div style={{ height: "20px" }} />

      {/* ADD/SETUP BASE RESUME */}
      <SetupResumeButtonContainer>
        <NavLink
          to="/setup-resume"
          style={{
            textDecoration: "none",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="btn-setup-resume"
        >
          <SetupResumeButton>
            <SetupResumeButtonSpan>
              <AddIcon />
            </SetupResumeButtonSpan>
          </SetupResumeButton>
        </NavLink>
      </SetupResumeButtonContainer>

      <div style={{ height: "20px" }} />
    </BaseResumesContainer>
  );
};
