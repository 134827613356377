// hooks/useGooglePickerHandler.ts

import { useCallback } from "react";

// Custom hook for handling Google Picker change events
export const useGooglePickerHandler = (
  getAccessToken: () => string | null, // Getter to retrieve the current access token
  setSelectedDoc: (doc: any) => void, // Setter for the selected document
  setResumeInput: (text: string) => void // Setter for the resume content
) => {
  // Utility: fetch file metadata from Google Drive
  const fetchFileMetadata = async (fileId: string, token: string) => {
    const response = await fetch(
      `https://www.googleapis.com/drive/v3/files/${fileId}?fields=webViewLink,name,mimeType,thumbnailLink,createdTime,modifiedTime,owners(displayName,emailAddress)`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Error fetching file metadata: ${response.status} ${response.statusText} - ${errorText}`
      );
    }
    return await response.json();
  };

  // Main handler for the picker change event
  const handlePickerChange = useCallback(
    async (data: any) => {
      const token = getAccessToken();
      if (!token) {
        console.error("Access token is null or undefined.");
        return;
      }
      if (data.action === window.google.picker.Action.PICKED) {
        const doc = data.docs[0];
        try {
          const metadataResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${doc.id}?fields=name,webViewLink,mimeType,thumbnailLink,createdTime,modifiedTime,owners(displayName,emailAddress)`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (!metadataResponse.ok) {
            const errorText = await metadataResponse.text();
            throw new Error(
              `Error fetching file details: ${metadataResponse.status} ${metadataResponse.statusText} - ${errorText}`
            );
          }
          const fileDetails = await metadataResponse.json();

          // Build the initial selected document object
          const selectedDoc = {
            id: doc.id,
            title: fileDetails.name || doc.name || doc.title,
            url: fileDetails.webViewLink || doc.url,
            mimeType: fileDetails.mimeType || doc.mimeType,
            thumbnailUrl: fileDetails.thumbnailLink || doc.thumbnailUrl,
            createdDate: fileDetails.createdTime || doc.createdDate,
            modifiedDate: fileDetails.modifiedTime || doc.modifiedDate,
            ownerEmail:
              fileDetails.owners && fileDetails.owners.length > 0
                ? fileDetails.owners[0].emailAddress
                : undefined,
          };

          setSelectedDoc(selectedDoc);

          // Create a copy of the file
          const copyResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${doc.id}/copy`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ name: `Copy of ${selectedDoc.title}` }),
            }
          );
          if (!copyResponse.ok) {
            const errorText = await copyResponse.text();
            throw new Error(
              `Error copying file: ${copyResponse.status} ${copyResponse.statusText} - ${errorText}`
            );
          }

          // Utility: make the file public
          const makeFilePublic = async (fileId: string, token: string) => {
            await fetch(
              `https://www.googleapis.com/drive/v3/files/${fileId}/permissions`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  role: "reader",
                  type: "anyone",
                }),
              }
            );
          };

          const copiedFile = await copyResponse.json();
          await makeFilePublic(copiedFile.id, token);

          const copiedFileMetadata = await fetchFileMetadata(
            copiedFile.id,
            token
          );

          // Update selected document with copied file info
          const updatedDoc = {
            ...selectedDoc,
            id: copiedFile.id,
            url: copiedFileMetadata.webViewLink,
            mimeType: copiedFileMetadata.mimeType,
            thumbnailUrl: copiedFileMetadata.thumbnailLink,
            createdDate: copiedFileMetadata.createdTime,
            modifiedDate: copiedFileMetadata.modifiedTime,
            ownerEmail:
              copiedFileMetadata.owners && copiedFileMetadata.owners.length > 0
                ? copiedFileMetadata.owners[0].emailAddress
                : undefined,
          };

          setSelectedDoc(updatedDoc);

          // Export document content
          const contentResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${copiedFile.id}/export?mimeType=text/plain`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (!contentResponse.ok) {
            const errorText = await contentResponse.text();
            throw new Error(
              `Error fetching document content: ${contentResponse.status} ${contentResponse.statusText} - ${errorText}`
            );
          }
          const textContent = await contentResponse.text();
          setResumeInput(textContent);
        } catch (error) {
          console.error("Error processing document:", error);
        }
      }
    },
    [getAccessToken, setSelectedDoc, setResumeInput]
  );

  return handlePickerChange;
};
