// src/pages/Make/components/MakeInputResume/MakeInputResume.tsx

import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { AppContext } from "@contexts/AppContext";
import { config } from "config";
import {
  MakeSubmoduleContainer,
  MakeSubmoduleHeader,
  MakeButton,
  MakeButtonLarge,
  MakeTextArea,
  MakeInput,
} from "../../Make.styled";
import { MakeInputAreaContainer } from "./MakeInputResume.styled";
import {
  CloudUpload as UploadIcon,
  FontDownload as InputIcon,
  AddToDrive as GoogleDriveIcon,
  FileUploadRounded as FileUploadIcon,
} from "@mui/icons-material";
import { GooglePicker } from "..";
import { GoogleDriveSVG } from "@assets/GoogleDriveSVG";
import { GoogleDocThumbnail } from "@components/GoogleDocThumbnail/GoogleDocThumbnail";
import { SVGWrapper } from "@components/primitives";
import { useGooglePickerHandler } from "../../hooks/useGooglePickerHandler";

import { triggerSignUpWorkflow } from "@services/auth/authWebhookSignup";
import { extractEmailFromToken } from "../../utils/tokenUtils";

interface MakeInputResumeProps {
  onNext: () => void;
}

export const MakeInputResume: React.FC<MakeInputResumeProps> = observer(
  ({ onNext }) => {
    const [inputMethod, setInputMethod] = useState<
      "paste" | "upload" | undefined
    >(undefined);
    const accessTokenRef = useRef<string | null>(null);
    const { makeStore } = useContext(AppContext);

    useEffect(() => {
      makeStore.persistState();
    }, [makeStore.selectedDoc, makeStore.jobDescriptionInput]);

    useEffect(() => {
      if (makeStore.selectedDoc) {
        console.log(
          "Restoring selected Document from store:",
          makeStore.selectedDoc
        );
      }
    }, [makeStore.selectedDoc]);

    const handleSelectPaste = () => setInputMethod("paste");
    const handleSelectUpload = () => setInputMethod("upload");
    const handleBack = () => setInputMethod(undefined);

    const handleResumeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      makeStore.setResumeInput(e.target.value);
    };

    // Getter for the current access token
    const getAccessToken = useCallback(
      () => accessTokenRef.current,
      [accessTokenRef.current]
    );

    // Use the custom hook to get the picker change handler
    const handlePickerChange = useGooglePickerHandler(
      getAccessToken,
      makeStore.setSelectedDoc.bind(makeStore),
      makeStore.setResumeInput.bind(makeStore)
    );

    const clearSelectDoc = () => {
      makeStore.setSelectedDoc(null);
    };

    const onAuthenticate = (token: string) => {
      console.log("Authenticated with token:", token);
      accessTokenRef.current = token;
      // Extract the email from the token and trigger the sign-up workflow if needed.
      const userEmail = extractEmailFromToken(token);
      if (userEmail) {
        // Note: You might choose to debounce this or add UI feedback (e.g., showing AccountSetupModal)
        triggerSignUpWorkflow({ email: userEmail });
      } else {
        console.error("Unable to extract user email from token.");
      }
    };

    return (
      <MakeSubmoduleContainer>
        <MakeSubmoduleHeader>Input Resume</MakeSubmoduleHeader>
        <MakeInputAreaContainer>
          {/* Input Method Selection */}
          <div style={{ display: "flex", gap: "20px", flexDirection: "row" }}>
            <GooglePicker
              clientId={config.googlePicker.clientId}
              developerKey={config.googlePicker.apiKey}
              scope={[
                "https://www.googleapis.com/auth/drive.metadata.readonly",
                "https://www.googleapis.com/auth/drive.file",
                "https://www.googleapis.com/auth/drive.readonly",
              ]}
              onAuthenticate={onAuthenticate}
              onChange={handlePickerChange}
              onAuthFailed={(data) => console.log("on auth failed:", data)}
              multiselect={true}
              navHidden={true}
              mimeTypes={["application/vnd.google-apps.document"]}
              query={"resume"}
              viewId={"DOCS"}
            >
              <MakeButtonLarge>
                <SVGWrapper $width="64px" $height="64px" $padding="8px">
                  <GoogleDriveSVG />
                </SVGWrapper>
                Select Google Doc
              </MakeButtonLarge>
            </GooglePicker>
          </div>

          {/* Display selected document thumbnail and controls */}
          {makeStore.selectedDoc && makeStore.selectedDoc.thumbnailUrl && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <GoogleDocThumbnail selectedDoc={makeStore.selectedDoc} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <MakeButton onClick={clearSelectDoc}>Clear</MakeButton>
                <MakeButton onClick={onNext}>Next</MakeButton>
              </div>
            </div>
          )}

          {/* Paste Resume Input */}
          {inputMethod === "paste" && (
            <>
              <MakeTextArea
                placeholder="Paste your resume here"
                rows={10}
                value={makeStore.resumeInput}
                onChange={handleResumeChange}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <MakeButton onClick={handleBack}>Back</MakeButton>
                <MakeButton onClick={onNext}>Next</MakeButton>
              </div>
            </>
          )}

          {/* Upload Resume Input */}
          {inputMethod === "upload" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <MakeButton>
                  <FileUploadIcon />
                  Choose File
                </MakeButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <MakeButton onClick={handleBack}>Back</MakeButton>
                <MakeButton onClick={onNext}>Next</MakeButton>
              </div>
            </>
          )}
        </MakeInputAreaContainer>
      </MakeSubmoduleContainer>
    );
  }
);
