// src/pages/UserMakes/UserMakesTable.tsx

import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import { toast } from "react-toastify";
import { AppContext } from "@contexts/AppContext";
import { app } from "@config/firebaseConfig";
import * as Primitive from "@components/primitives";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  UserMakesDateHeaderRow,
  UserMakesTableData,
  UserMakesTableRow,
  UserMakesSVGWrapper,
  UserMakesDateHeaderRowData,
  DropdownTrigger,
  DropdownContent,
  DropdownItem,
  JobTitleCell,
  MakeDateCell,
} from "./UserMakes.styled";
import ItemMenuMakes from "./ItemMenuMakes";
import { PDFSVG } from "@assets/PDFSVG";
import { GoogleDriveSVG } from "@assets/GoogleDriveSVG";
import {
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

import { ExpandedMakeRow } from "./components/ExpandedMakeRow";

const db = getFirestore(app);

export interface UserMake {
  id: string;
  makeDateTimeUNIX: string;
  makeTitle: string;
  makeJobPostURL: string;
  makeFileIDMakeFolder: string;
  makeFileIDMakeResumePDF: string;
  makeStatus: string;
}

interface UserMakesTableProps {
  makes: UserMake[];
  onDelete: (docId: string) => void;
  onMakeCoverLetter: (docId: string) => void;
  isDeleting: { [key: string]: boolean };
  isMakingCoverLetter: { [key: string]: boolean };
  isWideVW: boolean;
  isAbove860px: boolean;
  expandedRows: string[]; // Expanded make IDs
  toggleRow: (id: string) => void; // Toggle function for makes
}

export const UserMakesTable: React.FC<UserMakesTableProps> = ({
  makes,
  onDelete,
  onMakeCoverLetter,
  isDeleting,
  isMakingCoverLetter,
  isWideVW,
  isAbove860px,
  expandedRows,
  toggleRow,
}) => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("AppContext must be used within an AppProvider.");
  }
  const { userStore } = useContext(AppContext);

  // 0. Helpers

  const formatStatus = (status: string) =>
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  // 1. Group makes by date
  const groupedMakes = useMemo(() => {
    return makes
      .sort((a, b) => Number(b.makeDateTimeUNIX) - Number(a.makeDateTimeUNIX))
      .reduce(
        (acc, make) => {
          const currentDate = new Date(Number(make.makeDateTimeUNIX));
          const dayKey = currentDate.toDateString();
          if (!acc[dayKey]) {
            acc[dayKey] = [];
          }
          acc[dayKey].push(make);
          return acc;
        },
        {} as { [date: string]: UserMake[] }
      );
  }, [makes]);

  // 2. Date expansion toggles
  const [expandedDates, setExpandedDates] = useState<{
    [date: string]: boolean;
  }>({});

  useEffect(() => {
    const initialExpanded: { [date: string]: boolean } = {};
    Object.keys(groupedMakes).forEach((date) => {
      initialExpanded[date] = true; // expand all by default
    });
    setExpandedDates(initialExpanded);
  }, [groupedMakes]);

  const toggleDate = (date: string) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  // 3. Track statuses in local state
  const [statuses, setStatuses] = useState<{ [id: string]: string }>({});

  useEffect(() => {
    const initialStatuses: { [id: string]: string } = {};
    makes.forEach((make) => {
      initialStatuses[make.id] = make.makeStatus;
    });
    setStatuses(initialStatuses);
  }, [makes]);

  // 4. For editing *each field* in the expanded row
  // We'll store an object keyed by `make.id`. The value is a partial object of the fields we want to edit.
  const [editedData, setEditedData] = useState<{
    [id: string]: Partial<UserMake>;
  }>({});

  // Initialize editedData whenever makes changes
  useEffect(() => {
    const init: { [id: string]: Partial<UserMake> } = {};
    for (const make of makes) {
      init[make.id] = {
        // Copy each field we want to show/edit
        makeDateTimeUNIX: make.makeDateTimeUNIX,
        makeTitle: make.makeTitle,
        makeJobPostURL: make.makeJobPostURL,
        makeFileIDMakeFolder: make.makeFileIDMakeFolder,
        makeFileIDMakeResumePDF: make.makeFileIDMakeResumePDF,
        makeStatus: make.makeStatus,
      };
    }
    setEditedData(init);
  }, [makes]);

  const handleChange = (
    makeId: string,
    field: keyof UserMake,
    value: string
  ) => {
    setEditedData((prev) => ({
      ...prev,
      [makeId]: {
        ...prev[makeId],
        [field]: value,
      },
    }));
  };

  // 5. Handle status changes from the dropdown
  const handleStatusChange = (id: string, newStatus: string) => {
    setStatuses((prev) => ({ ...prev, [id]: newStatus }));
    // Also keep local edits in sync if we are also using them in expanded row
    setEditedData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        makeStatus: newStatus,
      },
    }));
    console.log(`Change status of ${id} to ${newStatus}`);
    // You could also do an immediate Firestore update if you want:
    // updateDoc(doc(db, "makes", id), { makeStatus: newStatus });
  };

  // 6. Save updated data to Firestore
  const [savingIds, setSavingIds] = useState<Set<string>>(new Set());

  const handleSave = async (id: string) => {
    if (savingIds.has(id)) {
      // Prevent further execution if this ID is already being saved
      console.warn(`Save for ${id} is already in progress.`);
      return;
    }

    // Add the ID to the set of saving IDs
    setSavingIds((prev) => new Set(prev).add(id));

    try {
      if (userStore.email) {
        await updateDoc(
          doc(db, "users", userStore.email, "userMakes", id),
          editedData[id]
        );
        toast.success("Saved!");
      } else {
        throw new Error("User email is null");
      }
    } catch (error: any) {
      console.error("Error updating doc:", error);
      toast.error("Error updating document: " + error.message);
    } finally {
      // Remove the ID from the saving set after processing
      setSavingIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  // 7. Determine how many columns to show
  const getVisibleColumnCount = (): number => {
    let count = 3; // DATE, JOB, DELETE (always visible)
    if (isAbove860px) {
      count += 2; // GDRIVE + PDF
    }
    if (isWideVW) {
      count += 1; // STATUS
    }
    return count;
  };

  const visibleColumnCount = getVisibleColumnCount();

  // 8. Render the table
  return (
    <>
      {makes.length === 0 ? (
        <UserMakesTableRow>
          <UserMakesTableData colSpan={visibleColumnCount}>
            No makes found.
          </UserMakesTableData>
        </UserMakesTableRow>
      ) : (
        Object.entries(groupedMakes).map(([dayKey, makesForDate]) => {
          const isDateExpanded = expandedDates[dayKey];
          const currentDate = new Date(dayKey);
          const formattedDate = currentDate
            .toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            .toUpperCase();

          return (
            <React.Fragment key={`group-${dayKey}`}>
              {/* Date Header Row */}
              <UserMakesDateHeaderRow
                onClick={() => toggleDate(dayKey)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    toggleDate(dayKey);
                  }
                }}
                tabIndex={0}
                style={{ cursor: "pointer" }}
                role="button"
                aria-expanded={isDateExpanded}
              >
                <UserMakesDateHeaderRowData colSpan={visibleColumnCount}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>{formattedDate}</span>
                    <ExpandMoreIcon
                      style={{
                        transform: isDateExpanded
                          ? "rotate(0deg)"
                          : "rotate(90deg)",
                        transition: "transform 0.2s",
                      }}
                    />
                  </div>
                </UserMakesDateHeaderRowData>
              </UserMakesDateHeaderRow>

              {/* The actual "make" rows for this date */}
              {isDateExpanded &&
                makesForDate.map((make) => {
                  const isMakeExpanded = expandedRows.includes(make.id);

                  return (
                    <React.Fragment key={`make-${make.id}`}>
                      <UserMakesTableRow
                        onClick={() => toggleRow(make.id)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            toggleRow(make.id);
                          }
                        }}
                        tabIndex={0}
                        style={{ cursor: "pointer" }}
                        role="button"
                        aria-expanded={isMakeExpanded}
                      >
                        {/* DATE Column */}
                        <MakeDateCell>
                          {currentDate.toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                          })}
                        </MakeDateCell>

                        {/* JOB Column */}
                        <JobTitleCell>
                          <Primitive.TableDataContentLeft>
                            <a
                              href={make.makeJobPostURL}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              {make.makeTitle}
                            </a>
                          </Primitive.TableDataContentLeft>
                        </JobTitleCell>

                        {/* GDRIVE Column */}
                        {isAbove860px && (
                          <UserMakesTableData>
                            <a
                              href={`https://drive.google.com/drive/folders/${make.makeFileIDMakeFolder}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Primitive.TableDataContentCentered>
                                <Primitive.TableSVGButtonWrapper>
                                  <UserMakesSVGWrapper>
                                    <GoogleDriveSVG />
                                  </UserMakesSVGWrapper>
                                </Primitive.TableSVGButtonWrapper>
                              </Primitive.TableDataContentCentered>
                            </a>
                          </UserMakesTableData>
                        )}

                        {/* PDF Column */}
                        {isAbove860px && (
                          <UserMakesTableData>
                            <a
                              href={`https://drive.google.com/file/d/${make.makeFileIDMakeResumePDF}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Primitive.TableDataContentCentered>
                                <Primitive.TableSVGButtonWrapper>
                                  <UserMakesSVGWrapper>
                                    <PDFSVG />
                                  </UserMakesSVGWrapper>
                                </Primitive.TableSVGButtonWrapper>
                              </Primitive.TableDataContentCentered>
                            </a>
                          </UserMakesTableData>
                        )}

                        {/* STATUS Dropdown */}
                        {isWideVW && (
                          <UserMakesTableData>
                            <Primitive.TableDataContentCentered>
                              <DropdownMenu.Root>
                                <DropdownTrigger
                                  onClick={(e) => e.stopPropagation()} // prevent toggling the row
                                >
                                  <span>{formatStatus(statuses[make.id])}</span>
                                  <ExpandMoreIcon
                                    style={{ fontSize: "1rem" }}
                                  />
                                </DropdownTrigger>
                                <DropdownContent sideOffset={5}>
                                  {[
                                    "Ready",
                                    "Submitted",
                                    "Interviewing",
                                    "Rejected",
                                    "Accepted",
                                  ].map((status) => (
                                    <DropdownItem
                                      key={status}
                                      onSelect={(e) => {
                                        e.stopPropagation();
                                        handleStatusChange(make.id, status);
                                      }}
                                    >
                                      {status}
                                    </DropdownItem>
                                  ))}
                                </DropdownContent>
                              </DropdownMenu.Root>
                            </Primitive.TableDataContentCentered>
                          </UserMakesTableData>
                        )}

                        {/* MENU Column */}
                        <UserMakesTableData>
                          <ItemMenuMakes
                            trigger={
                              <Primitive.TableDataContentCentered>
                                <Primitive.TableSVGButtonWrapper>
                                  <UserMakesSVGWrapper>
                                    <MoreVertIcon />
                                  </UserMakesSVGWrapper>
                                </Primitive.TableSVGButtonWrapper>
                              </Primitive.TableDataContentCentered>
                            }
                            sideOffset={8}
                            align="end"
                            onMakeCoverLetter={() => onMakeCoverLetter(make.id)}
                            onDelete={() => onDelete(make.id)}
                            onExpand={() => toggleRow(make.id)}
                            googleDriveUrl={`https://drive.google.com/drive/folders/${make.makeFileIDMakeFolder}`}
                            pdfUrl={`https://drive.google.com/file/d/${make.makeFileIDMakeResumePDF}`}
                          />
                        </UserMakesTableData>
                      </UserMakesTableRow>

                      {/* Expanded Row with multiple fields instead of one big JSON textarea */}
                      {isMakeExpanded && (
                        <ExpandedMakeRow
                          colSpan={visibleColumnCount}
                          makeId={make.id}
                          editedData={editedData[make.id] ?? {}}
                          onChange={(
                            makeId: string,
                            field: keyof UserMake,
                            value: string
                          ) => handleChange(makeId, field, value)}
                          onSave={(makeId) => handleSave(makeId)}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          );
        })
      )}
    </>
  );
};
