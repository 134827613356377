// src/components/TopBar/TopBar.tsx

import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { SVGWrapper } from "@components/primitives";
import logoSVG from "@assets/logo6.svg";

// Updated AppLogoContainer to match TopBar height
export const AppLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  width: min-content;
  margin-left: 6px;
  justify-content: flex-start;
  cursor: pointer;
  transition: scale 0.3s ease;
  transition: filter 0.3s ease;

  @media (max-width: 640px) {
    position: fixed;
    left: calc(50% - 21px);
    transform: translateX(-50%);
    /* opacity: 1;
    transition: opacity 0.2s ease;

    @media (max-width: 600px) {
      opacity: 0;
    } */
  }

  @media (hover: hover) {
    &:hover {
      scale: 0.99;
      filter: brightness(1.5);
    }
  }
`;

export const TopBarTitle = styled.div`
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #333;
  letter-spacing: -0.33px;
`;

export const TopBarAppLogo: React.FC = observer(() => {
  const handleLogoClick = () => {
    window.location.href = "/";
  };

  return (
    <>
      <AppLogoContainer onClick={handleLogoClick}>
        <SVGWrapper $width="42px" $height="42px" $padding="0" $margin="0">
          <img
            src={logoSVG}
            alt="RunoIcon"
            width="100%"
            height="100%"
            style={{ filter: "invert(.35)" }}
          />
        </SVGWrapper>
        <TopBarTitle>RunoJobs</TopBarTitle>
      </AppLogoContainer>
    </>
  );
});
